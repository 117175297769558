.section {
  padding: 4em 2em;
  background-color: #f4f4f4;
  border: 1px solid #ddd;
}

.experience-container {
  display: flex;
  justify-content: space-around; 
  flex-wrap: wrap; 
  gap: 20px; 
  background-color: #d9e3da; 
  padding: 20px; 
}

.experience-item {
  background: white; 
  padding: 20px; 
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  flex: 1; 
  min-width: 200px;
  max-width: 300px; 
  text-align: center;
}

.experience-item h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.experience-item h4 {
  font-size: 1em;
  font-weight: normal;
  color: #2f4c39;
  margin-bottom: 10px;
}

.experience-item p {
  font-size: 0.9em;
  color: #666;
}

.experience-section h2 {
  font-size: 2em;
  margin-bottom: 1em;
  margin: 1em 0 0.5em 2em;
  color: black;
}

.company-logo {
  max-width: 80px; 
  max-height: 40px;
  margin-bottom: 10px;
}
