body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #fafafa;
}

.section {
  padding: 4em 2em;
  margin: 1em;
  background-color: #fafafa;
  border: 1px solid #fafafa;
}

h2 {
  color: #caebf2;
}

