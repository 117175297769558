.about-section {
    display: flex;
    align-items: center;
    padding: 4em 2em;
    background-color: #d9e3da;
  }
  
  .profile-pic {
    border-radius: 50%;
    width: 300px;
    height: 300px;
    margin-right: 2em;
  }
  
  .about-content {
    max-width: 800px;
  }
  
  .about-content h2 {
    font-size: 2em;
    margin-bottom: 0.5em;
    color: black;
  }
  
  .about-content p {
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 1em;
  }
  
  .social-links a {
    margin-right: 1em;
    color: #007bff;
    text-decoration: none;
  }
  
  .social-links a:hover {
    text-decoration: underline;
  }
  