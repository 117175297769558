.footer {
    background-color: #f4f4f4;
    color: #333;
    text-align: center;
    padding: 2em 1em;
    font-size: 0.8em;
    margin-top: 2em;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .footer p {
    margin: 0.5em 0;
  }
  
  .footer-links {
    margin-top: 1em;
  }
  
  .footer-links a {
    margin: 0 0.8em;
    color: #007bff;
    text-decoration: none;
    font-size: 0.7em;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  